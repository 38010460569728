import React from "react";
import Tip from "../Tip";
import { Icon } from "@iconify/react";
import { BellAlertIcon } from "@heroicons/react/20/solid";

const HelpItem = ({ title, explanation, example, caution }) => {
  return (
    <div className="py-2">
      <h2 className="text-lg font-semibold">{title}</h2>
      <div className="tracking-normal">{explanation()}</div>
      {example && <Tip>{example}</Tip>}
      {caution && (
        <Tip
          color="red"
          icon
          //  display a warning icon
        >
          <Icon
            icon="carbon:warning-filled"
            className="my-auto inline-flex w-4 h-4 mr-1 text-red-500"
          />
          {caution}
        </Tip>
      )}
      <hr className="mt-4" />
    </div>
  );
};

export default HelpItem;
