import { Fragment, useEffect, useState } from "react";
import { utils } from "../../../modules/_utils";
import Button from "../../../elements/Button";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import QuestionToolTip from "../../../components/QuestionToolTip";
import { pricing_explanations } from "../../../modules/pricing_explanations";
import Badge from "../../../components/Badge";
import WYSIWYGViewer from "../../../components/WYSIWYGViewer";
import PDFViewerModal from "../../../components/PDFViewerModal";
import { PaperClipIcon } from "@heroicons/react/20/solid";
import { Solicitations } from "../../../modules/_solicitations";
import DropdownMenu from "../../../components/DropdownMenu";
import { Icon } from "@iconify/react";
import { VENDOR_NOTIFIED_ICON } from "../../../constants/icon_constants";
import Tooltip from "../../../components/Tooltip";

export default function BidCompareTableBodyV2({
  allResponses,
  allLineItems,
  allPricingItems,
  responses,
  showLineItems,
}) {
  // showLineItems = false;
  const [allOptionDescriptions, setAllOptionDescriptions] = useState([]);
  const [services, setServices] = useState(allLineItems);

  useEffect(() => {
    // Initialize an object to store unique descriptions for each service
    let optionDescriptions = {};

    // Loop through all responses
    allResponses &&
      allResponses.forEach((response) => {
        if (response.services) {
          // Loop through each service in response.services
          Object.entries(response.services).forEach(([lineItem, service]) => {
            if (service.options) {
              // Initialize a Set to store unique descriptions for the current service
              if (!optionDescriptions[lineItem]) {
                optionDescriptions[lineItem] = new Set();
              }

              // Loop through each option to get the description
              service.options.forEach((option) => {
                optionDescriptions[lineItem].add(option.description);
              });
            }
          });
        }
      });

    // Convert Sets to arrays and format as required
    const formattedOptionDescriptions = Object.keys(optionDescriptions).map(
      (key) => ({
        [key]: Array.from(optionDescriptions[key]),
      })
    );

    // Flatten the array of objects into a single array
    const flattenedOptionDescriptions = formattedOptionDescriptions.reduce(
      (acc, curr) => {
        const key = Object.keys(curr)[0];
        acc[key] = curr[key];
        return acc;
      },
      {}
    );

    // You can now use flattenedOptionDescriptions as needed
    console.log(
      "BidCompareTableBodyV2 flattenedOptionDescriptions",
      flattenedOptionDescriptions
    );
    setAllOptionDescriptions(flattenedOptionDescriptions);
  }, [allResponses]);

  useEffect(() => {
    setServices(allLineItems);
  }, [allLineItems]);

  useEffect(() => {
    console.log("BidCompareTableBodyV2 allResponses", allResponses);
  }, [allResponses]);
  useEffect(() => {
    setServices(allLineItems);
  }, [allLineItems]);
  useEffect(() => {
    console.log("BidCompareTableBodyV2 allResponses", allResponses);
    console.log("BidCompareTableBodyV2 allLineItems", allLineItems);
    console.log("BidCompareTableBodyV2 allPricingItems", allPricingItems);
    console.log("BidCompareTableBodyV2 responses", responses);
  }, [allResponses, allLineItems, allPricingItems, responses]);

  const getTableHeader = () => {
    return (
      <tr className="border-t border-gray-200">
        <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          Item
        </th>
        <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          Qty
        </th>
        <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          Unit Price
        </th>
        <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          Total
        </th>
      </tr>
    );
  };

  const getTablePreview = (option) => {
    return Object.entries(option.pricing).map(([pricingKey, pricingValue]) => (
      <tr key={pricingKey}>
        <td className="text-left px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          {pricingKey}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
          {pricingValue.qty}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
          ${pricingValue.unitPrice}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
          ${pricingValue.price}
        </td>
      </tr>
    ));
  };

  const getTable = (option, key) => {
    return (
      <div className="min-w-full ">
        <table className="w-full divide-y divide-gray-200 border-gray-200 mb-8 ">
          <thead>{getTableHeader()}</thead>
          <tbody className="bg-white divide-y divide-gray-200 text-right">
            {getTablePreview(option)}
            <tr>
              <td
                colSpan="3"
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-right"
              >
                Total
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-black text-right">
                ${option.price.toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const getExclusionsTable = (option) => {
    const questions = [
      { question: "Mailing per day limit", answer: "1 million" },
      { question: "Price includes requested page number", answer: "Yes" },
      { question: "Price includes 2 pages", answer: "Yes" },
      { question: "Return mail included in price", answer: "Yes" },
      { question: "Return mail assumed %", answer: "5%" },
      { question: "Return mail fee above assumption", answer: "$1/individual" },
    ];
    return (
      <table className="min-w-full divide-y divide-gray-200 border-gray-200 mb-8">
        {/* <thead>
          <tr className="border-t border-gray-200">
            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Question
            </th>

            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Response
            </th>
          </tr>
        </thead> */}
        <tbody className="bg-white divide-y divide-gray-200 text-right">
          {questions.map((item) => (
            <Fragment key={item.question}>
              <tr>
                <td className="text-left px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {item.question}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                  {item.answer}
                </td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
    );
  };

  const getAdditionalComments = (option) => {
    //<div className="text-gray-600 text-sm">{option.additional_comments}</div>

    if (option && option.additional_comments_object) {
      return (
        <div>
          {" "}
          <div className="border-t border-b border-gray-200 px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Additional Comments
          </div>
          <WYSIWYGViewer editorState={option.additional_comments_object} />
        </div>
      );
    } else if (option && option.additional_comments) {
      return (
        <div>
          <div className="font-bold">Additional Details </div>
          {option.additional_comments}
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <tbody className="w-full">
      {services &&
        services.length > 0 &&
        services.map((service) => {
          let tmp = [];
          return (
            <Fragment>
              <tr
                colSpan={allResponses?.length + 1}
                className="h-12  bg-brand-100 relative  py-2 uppercase text-sm leading-6 text-gray-900"
              >
                <th
                  id={`service-${service}`}
                  // scope="col"
                  colSpan={1}
                  // class={`sticky left-0 h-fit  px-6 py-3`}
                  style={{
                    width: "200px",
                    maxWidth: "200px",
                    // minWidth: "200px",
                  }}
                  class={`w-full col-span-full sticky left-0 h-fit px-6 py-3 border-r border-gray-200`}
                >
                  {utils.cleanUpKeys(service)}
                </th>
                {/* for 0 to allResponses.length, return <th */}
                {allResponses &&
                  allResponses.length > 0 &&
                  allResponses.map((response) => {
                    return (
                      <th
                        id={`service-${service}-response-${response._id}`}
                        colSpan={1}
                        style={{
                          width: "200px",
                          maxWidth: "200px",
                          minWidth: "200px",
                        }}
                        class={`border-r w-full col-span-full sticky left-0 h-fit px-6 py-3 `}
                      >
                        <div className="flex items-center">
                          {response.services &&
                          response.services[service] &&
                          response.services[service]?.price
                            ? "Estimated:" +
                              parseFloat(
                                response.services[service]?.price || 0
                              ).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            : ""}{" "}
                          {response.services &&
                            response.services[service] &&
                            response.services[service]?.price && (
                              <QuestionToolTip
                                // position="bottom"
                                field="Estimated price"
                                text="This is the estimated price based on the information you provided in your bid request and is subject to change based on the final scope of work."
                              />
                            )}
                        </div>
                      </th>
                    );
                  })}
              </tr>
              {/* {allOptionDescriptions &&
                allOptionDescriptions[service] &&
                allOptionDescriptions[service].length > 0 &&
                allOptionDescriptions[service].map((optionDescription, idx) => (
                  <tr className="h-4" key={idx}>
                    <th
                      scope="col"
                      className="sticky bg-white left-0 px-6 py-3 border-r border-gray-200 align-top"
                      style={{
                        width: "300px",
                        maxWidth: "300px",
                        minWidth: "300px",
                      }}
                    >
                      {optionDescription} || test
                    </th>
                  </tr>
                ))} */}

              {allOptionDescriptions &&
                allOptionDescriptions[service] &&
                allOptionDescriptions[service].length > 0 &&
                allOptionDescriptions[service].map((key, idx) => {
                  console.log("allOptionDescriptions key", key);
                  if (tmp.includes(key)) {
                    return;
                  } else {
                    tmp.push(key);
                  }
                  return (
                    <>
                      <tr class=" h-4 py-4 bg-white border-b border-gray-200 dark:border-gray-200">
                        <th
                          id={`service-${service}-option-${idx}`}
                          scope="col"
                          class={` sticky bg-white left-0 px-6 py-3 border-r border-gray-200`}
                          // style={{
                          //   width: "100px",
                          //   maxWidth: "100px",
                          //   minWidth: "100px",
                          // }}
                        >
                          {key}
                        </th>

                        {responses &&
                          responses.length > 0 &&
                          responses.map((response) => {
                            if (!response.services) {
                              return (
                                <td className="text-gray-600 font-normal align-top "></td>
                              );
                            }
                            // let line_Item = response.services[service];
                            // let pricingItem = response?.services[service]?.key;
                            // let recurrence = pricingItem?.recurrence;
                            // let unit = pricingItem?.unit;
                            // let billing_interval =
                            //   pricingItem?.billing_interval;
                            // let type = pricingItem?.type;
                            return (
                              <td className="border-r text-gray-600 font-normal align-top ">
                                <li
                                  key={key}
                                  className="w-full font-medium flex gap-x-3 py-2 px-2"
                                >
                                  <div className="space-y-2 w-full pr-4">
                                    {response.services[service].options && (
                                      <>
                                        {response.services[service].options.map(
                                          (option) => {
                                            if (option.description === key) {
                                              console.log("OPTION", option);
                                              return (
                                                <div className="w-full flex flex-col items-start white-space-nowrap space-x-2">
                                                  {showLineItems && (
                                                    <div className="font-bold w-full text-xl text-center py-4">
                                                      {option.description}
                                                    </div>
                                                  )}
                                                  {showLineItems &&
                                                  option.pricing ? (
                                                    <div className="w-full flex flex-col">
                                                      {getTable(option, key)}
                                                      <div className="border-t border-b border-gray-200 px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        {" "}
                                                        Exclusions and
                                                        Limitations
                                                      </div>
                                                      {getExclusionsTable(
                                                        option
                                                      )}
                                                      <div className="py-6">
                                                        None
                                                      </div>
                                                      {getAdditionalComments(
                                                        option
                                                      )}
                                                    </div>
                                                  ) : (
                                                    <div className="flex items-center white-space-nowrap space-x-2">
                                                      {/* format option.price as us currency with a comma */}
                                                      {option.price && (
                                                        <span>
                                                          {"$"}
                                                          {option.price.toLocaleString(
                                                            "en-US",
                                                            {
                                                              minimumFractionDigits: 2,
                                                              maximumFractionDigits: 2,
                                                            }
                                                          )}
                                                        </span>
                                                      )}
                                                      <QuestionToolTip
                                                        field="Pricing Details"
                                                        size="xl"
                                                        text={getTable(
                                                          option,
                                                          key
                                                        )}
                                                      />
                                                    </div>
                                                  )}
                                                </div>
                                              );
                                            }
                                          }
                                        )}

                                        {/* <div className="flex items-center white-space-nowrap space-x-2">
                                            Standard Pricing{" "}
                                            <QuestionToolTip
                                              field="Standard pricing"
                                              text={pricing_explanations.StandardPricing(
                                                response.services[service]
                                                  .pricing?.[key]
                                              )}
                                            />
                                            {response.services[service]
                                              .pricing?.[key]?.recurrence ==
                                            "Recurring" ? (
                                              <Badge
                                                color="secondary"
                                                className=" "
                                              >
                                                {" "}
                                                RECURRING{" "}
                                              </Badge>
                                            ) : (
                                              <Badge
                                                color="secondary"
                                                className=" "
                                              >
                                                {" "}
                                                ONE-TIME FEE{" "}
                                              </Badge>
                                            )}
                                          </div>
                                          <div className="text-gray-600 font-normal ">
                                            <span className="lowercase">
                                              {" $"}
                                              {response.services[service]
                                                .pricing &&
                                                response.services[service]
                                                  .pricing[key] &&
                                                response.services[service]
                                                  .pricing[key].price}{" "}
                                              {response.services[service]
                                                ?.pricing[key]?.unit !==
                                                "Fixed Price" && " per "}
                                              {response.services[service]
                                                .pricing &&
                                                response.services[service]
                                                  .pricing[key] &&
                                                response.services[service]
                                                  .pricing[key].unit !==
                                                  undefined &&
                                                response.services[service]
                                                  .pricing[key].unit}
                                              {response.services[service]
                                                .pricing?.[key]?.recurrence ==
                                                "Recurring" &&
                                                " per " +
                                                  response.services[service]
                                                    .pricing?.[key]
                                                    ?.billing_interval}
                                            </span>
                                          </div>{" "} */}
                                      </>
                                    )}
                                  </div>
                                </li>
                              </td>
                            );
                          })}
                      </tr>
                    </>
                  );
                })}
              <tr className="h-4">
                <th
                  scope="col"
                  class={` sticky bg-white left-0 px-6 py-3 border-r border-gray-200 align-top`}
                  // style={{
                  //   width: "300px",
                  //   maxWidth: "300px",
                  //   minWidth: "300px",
                  // }}
                >
                  Additional Comments
                </th>
                {responses &&
                  responses.length > 0 &&
                  responses.map((response) => {
                    if (!response.services) {
                      return (
                        <td className="text-gray-600 font-normal align-top"></td>
                      );
                    }
                    return (
                      <td className="border-r text-gray-600 text-sm py-2 px-2 align-top">
                        {response.services[service] &&
                        response.services[service]
                          .additional_comments_object ? (
                          <WYSIWYGViewer
                            editorState={
                              response.services[service]
                                .additional_comments_object
                            }
                          />
                        ) : (
                          response.services[service] &&
                          response.services[service].additional_comments
                        )}
                      </td>
                    );
                  })}
              </tr>
            </Fragment>
          );
        })}
    </tbody>
  );
}
