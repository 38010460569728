import { Fragment, useEffect, useState } from "react";
import { utils } from "../../../modules/_utils";
import Button from "../../../elements/Button";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import QuestionToolTip from "../../../components/QuestionToolTip";
import { pricing_explanations } from "../../../modules/pricing_explanations";
import Badge from "../../../components/Badge";
import WYSIWYGViewer from "../../../components/WYSIWYGViewer";

export default function BidCompareTableBodyV1({
  allResponses,
  allLineItems,
  allPricingItems,
  responses,
}) {
  return (
    <tbody className="w-full">
      {allLineItems &&
        allLineItems.length > 0 &&
        allLineItems.map((lineItem) => {
          let tmp = [];
          return (
            <Fragment>
              <tr
                colSpan={allResponses?.length + 1}
                className="  bg-brand-100 relative  py-2 uppercase text-sm leading-6 text-gray-900"
              >
                <th
                  // scope="col"
                  colSpan={1}
                  // class={`sticky left-0 h-fit  px-6 py-3`}
                  style={{
                    width: "300px",
                    maxWidth: "300px",
                    minWidth: "300px",
                  }}
                  class={`w-full col-span-full sticky left-0 h-fit px-6 py-3 border-r border-gray-200`}
                >
                  {utils.cleanUpKeys(lineItem)}
                </th>
                {/* for 0 to allResponses.length, return <th */}
                {allResponses &&
                  allResponses.length > 0 &&
                  allResponses.map((response) => {
                    return (
                      <th
                        colSpan={1}
                        style={{
                          width: "300px",
                          maxWidth: "300px",
                          minWidth: "300px",
                        }}
                        class={`border-r w-full col-span-full sticky left-0 h-fit px-6 py-3 `}
                      >
                        <div className="flex items-center">
                          {response.services &&
                          response.services[lineItem] &&
                          response.services[lineItem]?.price
                            ? "Estimated:" +
                              parseFloat(
                                response.services[lineItem]?.price || 0
                              ).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            : "No estimated price provided"}{" "}
                          {response.services &&
                            response.services[lineItem] &&
                            response.services[lineItem]?.price && (
                              <QuestionToolTip
                                // position="bottom"
                                field="Estimated price"
                                text="This is the estimated price based on the information you provided in your bid request and is subject to change based on the final scope of work."
                              />
                            )}
                        </div>
                      </th>
                    );
                  })}
              </tr>

              {allPricingItems &&
                allPricingItems[lineItem] &&
                allPricingItems[lineItem].length > 0 &&
                allPricingItems[lineItem].map((key, idx) => {
                  if (tmp.includes(key)) {
                    return;
                  } else {
                    tmp.push(key);
                  }
                  return (
                    <>
                      <tr class="py-6 bg-white border-b  dark:border-gray-700">
                        <th
                          scope="col"
                          class={` sticky bg-white left-0 px-6 py-3 border-r border-gray-200`}
                          style={{
                            width: "300px",
                            maxWidth: "300px",
                            minWidth: "300px",
                          }}
                        >
                          {key}
                        </th>

                        {responses &&
                          responses.length > 0 &&
                          responses.map((response) => {
                            if (!response.services) {
                              return (
                                <td className="text-gray-600 font-normal align-top "></td>
                              );
                            }
                            let line_Item = response.services[lineItem];
                            let pricingItem = response?.services[lineItem]?.key;
                            let recurrence = pricingItem?.recurrence;
                            let unit = pricingItem?.unit;
                            let billing_interval =
                              pricingItem?.billing_interval;
                            let type = pricingItem?.type;
                            return (
                              <td className="border-r text-gray-600 font-normal align-top ">
                                <li
                                  key={key}
                                  className=" font-medium flex gap-x-3 py-2 px-2"
                                >
                                  <div className="space-y-2">
                                    {response.services[lineItem] &&
                                    response.services[lineItem].pricing &&
                                    response.services[lineItem].pricing[key] &&
                                    response.services[lineItem].pricing &&
                                    response.services[lineItem].pricing[key]
                                      .type === "Standard" ? (
                                      <>
                                        <div className="flex items-center white-space-nowrap space-x-2">
                                          Standard Pricing{" "}
                                          <QuestionToolTip
                                            field="Standard pricing"
                                            text={pricing_explanations.StandardPricing(
                                              response.services[lineItem]
                                                .pricing?.[key]
                                            )}
                                          />
                                          {response.services[lineItem]
                                            .pricing?.[key]?.recurrence ==
                                          "Recurring" ? (
                                            <Badge
                                              color="secondary"
                                              className=" "
                                            >
                                              {" "}
                                              RECURRING{" "}
                                            </Badge>
                                          ) : (
                                            <Badge
                                              color="secondary"
                                              className=" "
                                            >
                                              {" "}
                                              ONE-TIME FEE{" "}
                                            </Badge>
                                          )}
                                        </div>
                                        <div className="text-gray-600 font-normal ">
                                          <span className="lowercase">
                                            {" $"}
                                            {response.services[lineItem]
                                              .pricing &&
                                              response.services[lineItem]
                                                .pricing[key] &&
                                              response.services[lineItem]
                                                .pricing[key].price}{" "}
                                            {response.services[lineItem]
                                              ?.pricing[key]?.unit !==
                                              "Fixed Price" && " per "}
                                            {response.services[lineItem]
                                              .pricing &&
                                              response.services[lineItem]
                                                .pricing[key] &&
                                              response.services[lineItem]
                                                .pricing[key].unit !==
                                                undefined &&
                                              response.services[lineItem]
                                                .pricing[key].unit}
                                            {response.services[lineItem]
                                              .pricing?.[key]?.recurrence ==
                                              "Recurring" &&
                                              " per " +
                                                response.services[lineItem]
                                                  .pricing?.[key]
                                                  ?.billing_interval}
                                          </span>
                                        </div>{" "}
                                      </>
                                    ) : (
                                      <div className="text-gray-600 space-y-2">
                                        {" "}
                                        <div className="flex items-center white-space-nowrap space-x-2">
                                          {response.services[lineItem] &&
                                            response.services[lineItem]
                                              .pricing &&
                                            response.services[lineItem].pricing[
                                              key
                                            ] &&
                                            response.services[lineItem].pricing[
                                              key
                                            ].type}{" "}
                                          {/* <QuestionToolTip
                                          field={
                                            response?.services[lineItem]
                                              ?.pricing[key]?.type +
                                            " pricing"
                                          }
                                          text={pricing_explanations.GetVolumeAndGraduatedPricing(
                                            response.services[lineItem]
                                              .pricing?.[key]
                                          )}
                                        />{" "} */}
                                          {response &&
                                          response.services &&
                                          response.services[lineItem] &&
                                          response?.services[lineItem]
                                            .pricing?.[key]?.recurrence ==
                                            "Recurring" ? (
                                            <Badge
                                              color="secondary"
                                              className=" "
                                            >
                                              {" "}
                                              RECURRING{" "}
                                            </Badge>
                                          ) : (
                                            response &&
                                            response.services &&
                                            response.services[lineItem] &&
                                            response.services[lineItem]
                                              .pricing?.[key]?.recurrence && (
                                              <Badge
                                                color="secondary"
                                                className=" "
                                              >
                                                {" "}
                                                ONE-TIME FEE{" "}
                                              </Badge>
                                            )
                                          )}
                                        </div>
                                        <div className="text-gray-600 font-normal space-y-2">
                                          {
                                            // if type is volume, loop through the volume tiers
                                            // and display the volume tier, price, and unit

                                            response.services[lineItem] &&
                                              response.services[lineItem]
                                                .pricing &&
                                              response.services[lineItem]
                                                .pricing[key] &&
                                              (response.services[lineItem]
                                                .pricing[key].type ==
                                                "Volume" ||
                                                response.services[lineItem]
                                                  .pricing[key].type ==
                                                  "Graduated") &&
                                              response.services[lineItem]
                                                .pricing[key].tiers &&
                                              response.services[
                                                lineItem
                                              ].pricing[key].tiers.map(
                                                (tier) => {
                                                  return (
                                                    <div
                                                      key={tier._id}
                                                      className="flex mt-2 gap-x-3 lowercase"
                                                    >
                                                      {tier.min} - {tier.max}:{" "}
                                                      {" $"}
                                                      {tier.price && tier.price}
                                                      {tier.price_type ==
                                                      "Recurring"
                                                        ? " per " +
                                                          response?.services[
                                                            lineItem
                                                          ]?.pricing[key]?.unit
                                                        : " fixed"}
                                                      {response.services[
                                                        lineItem
                                                      ].pricing?.[key]
                                                        ?.recurrence ==
                                                        "Recurring" &&
                                                        " per " +
                                                          response.services[
                                                            lineItem
                                                          ].pricing?.[key]
                                                            ?.billing_interval}
                                                    </div>
                                                  );
                                                }
                                              )
                                          }
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </li>
                              </td>
                            );
                          })}
                      </tr>
                    </>
                  );
                })}
              <tr className="h-4">
                <th
                  scope="col"
                  class={` sticky bg-white left-0 px-6 py-3 border-r border-gray-200 align-top`}
                  style={{
                    width: "300px",
                    maxWidth: "300px",
                    minWidth: "300px",
                  }}
                >
                  Additional Comments
                </th>
                {responses &&
                  responses.length > 0 &&
                  responses.map((response) => {
                    if (!response.services) {
                      return (
                        <td className="text-gray-600 font-normal align-top"></td>
                      );
                    }
                    return (
                      <td className="border-r text-gray-600 text-sm py-2 px-2 align-top">
                        {response.services[lineItem] &&
                        response.services[lineItem]
                          .additional_comments_object ? (
                          <WYSIWYGViewer
                            editorState={
                              response.services[lineItem]
                                .additional_comments_object
                            }
                          />
                        ) : (
                          response.services[lineItem] &&
                          response.services[lineItem].additional_comments
                        )}
                      </td>
                    );
                  })}
              </tr>
            </Fragment>
          );
        })}
      {/* {response.services &&
    Object.entries(response.services).map(([k, v]) => {
      return (
        <tr class="bg-white border-b  dark:border-gray-700">
          <th
            scope="row"
            class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
          >
            {idx} Service Name
          </th>
          <td class="px-6 py-4">{tier.min}</td>
          <td class="px-6 py-4"></td>
          <td class="px-6 py-4"></td>
          <td class="px-6 py-4 relative">
            <span className="bottom-5 absolute -right-4"></span>
          </td>
        </tr>
      );
    })} */}
    </tbody>
  );
}
