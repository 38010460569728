import Tip from "../Tip";
import HelpItem from "./HelpItem";

const helpItems = [
  {
    id: 0,
    title: "Getting Started",
    content: () => (
      <div className="tracking-normal space-y-4">
        <p>
          Welcome to the new Quote Builder. The goal of this new spreadsheet
          format is to make it faster for you to enter bids, provide a better
          format for bids requiring multiple options, and to make it easier for
          requestors to read and compare your quotes.{" "}
        </p>
        <Tip color="secondary">
          This new format was created at the request of our users who were
          frustrated with the free-for-all nature of the previous format. We
          understand that the change will take some getting used to, but we
          believe it will ultimately drive more business for you. Please reach
          out to our support team if you have any questions.
        </Tip>
        <p className="font-semibold">Here are a few things you need to know:</p>
        <p>
          <ul className="list-disc list-outside pl-4  space-y-2 mb-6">
            <li>
              You can create multiple quote options. To do this, when viewing a
              service, click Add Option.
            </li>
            <li>You must select a scenario for each option.</li>
            <li>Scenarios have pre-defined line items.</li>
            <li>
              We've added a few blank line items just in case you need them.{" "}
              <span className="font-bold">
                Use these with caution as it affects apples-to-apples
                comparison.
              </span>
            </li>
            <li>
              Fill in the line items that are necessary to ensure your quote
              total is accurate.
            </li>
          </ul>
        </p>
        <p className="font-semibold">A few words of caution:</p>
        <ul className="list-disc list-outside pl-4  space-y-2 mb-6">
          <li>
            Please ensure that your quote aligns with the requestor's bid
            request. If they are asking for something specific, please ensure
            your bid reflects that.
          </li>
          <li>
            Avoid adding optional line items that are not part of your core
            quote. These can be added in the Additional Comments.
          </li>
          <li>
            If you have any questions, leave a comment on the bid request.
          </li>
        </ul>
        <p className="font-semibold">We want to help</p>

        <p>If you have any questions, please reach out to our support team.</p>
      </div>
    ),
  },
  // {
  //   id: 1,
  //   title: "Options",
  //   content:
  //     "You can create as many options as necessary by clicking the Add Option tab. You can delete options by clicking the Delete option button.  ",
  //   example:
  //     "Example: You may need to create a fixed price option and a redemption option for the same request.",
  //   warning:
  //     "Your options should align to the requestor's bid request. Please limit the number of options to the minimum necessary.",
  // },
  // {
  //   id: 2,
  //   title: "Scenarios",
  //   content:
  //     "Breachlink has pre-created common scenarios that contain common items and services.  This ensures uniformity in your quotes.",
  //   example:
  //     "We realize that you may have additional items or services that are not included in the scenarios.  You can add those items as needed.",
  // },
  // {
  //   id: 3,
  //   title: "Line Items",
  //   content:
  //     "Line items are how you build your quote.  You can add as many line items as you need.  When adding a line item, you can choose from a variety of pricing structures. ",
  //   example:
  //     "Example: $100 per document for the first 10 documents, $90 per document for the next 10 documents, $80 per document for the next 10 documents",
  // },
  // {
  //   id: 4,
  //   title: "Limitations and Exclusions",
  //   content:
  //     "Line items are how you build your quote.  You can add as many line items as you need.  When adding a line item, you can choose from a variety of pricing structures. ",
  //   example:
  //     "Example: $100 per document for the first 10 documents, $90 per document for the next 10 documents, $80 per document for the next 10 documents",
  // },
  // {
  //   id: 5,
  //   title: "Additional Comments",
  //   content:
  //     "Volume pricing is a teir-based pricing structure.  Unlike Graduated Pricing, the price is based on the tier price for the total number of items. You can add as many tiers as you need. ",
  //   example:
  //     "Example: If you have a tier of 1-10 at a price of $10 each, and a tier of 11-20 at a price of $9 each, and you have 15 items, all items will be priced at $9 each. ",
  // },
  // {
  //   id: 7,
  //   title: "Tiers",
  //   content:
  //     "There are two ways to charge for a tier.  The first way is per unit. The second, is a flat rate. If you choose flat rate, you will charge a single price for all items in that tier.  If you choose per unit, the price will be multiplied by the number of items in the tier.",
  //   warning:
  //     "Use caution when pricing your tiers.  For example, billing a graduated price of $100 for the first 10 items, and $5 each for the next 5 items will result in a total price of $125.  However, if you bill a volume price and enter $100 for the first 10 items, and $5 for the next 5 items, the total price will be $75.",
  // },
  // {
  //   id: 5,
  //   title: "Service",
  //   content: "A service is what you'll be doing for your client. ",
  // },
  // {
  //   id: 6,
  //   title: "Unit",
  //   content: "A unit is how you charge for your service.",
  // },
];

const HelpItems = () => {
  return (
    <div className="h-full overflow-y-auto pr-2 pb-10 scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  scrollbar-track-rounded-full scrollbar-thumb-rounded-full">
      {helpItems.map((item) => (
        <HelpItem
          key={item.id}
          title={item.title}
          explanation={item.content}
          example={item.example}
          caution={item.warning}
        />
      ))}
    </div>
  );
};

export default HelpItems;
