import { useState } from "react";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
} from "@floating-ui/react";

// import "./styles.css";
const sizeClasses = (size) => {
  console.log("Tooltip size input:", size);
  let returnSize = "";
  switch (size) {
    case "xl":
      returnSize = " w-1/3 max-w-2/3 p-3 ";
      break;
    case "lg":
      returnSize = " w-72 max-w-72  p-3 ";
      break;
    case "md":
      returnSize = "w-56  max-w-56 p-3 ";
      break;
    case "sm":
      returnSize = " w-44 max-w-44 p-2 ";
      break;
    default:
      returnSize = " 2-56 max-w-56 p-3  ";
      break;
  }
  // console.log("Tooltip size output:", returnSize);
  return returnSize;
};

function Tooltip({
  children,
  description,
  helpIcon,
  title,
  size,
  bg,
  position,
}) {
  const finalClassName = `${sizeClasses(
    size
  )} z-50 rounded overflow-hidden text-slate-300 bg-slate-800`;
  console.log("Tooltip final className:", finalClassName);
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "top",
    // Make sure the tooltip stays on the screen
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        fallbackAxisSideDirection: "start",
      }),
      shift(),
    ],
  });

  // Event listeners to change the open state
  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  // Role props for screen readers
  const role = useRole(context, { role: "tooltip" });

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);

  return (
    <>
      <button
        ref={refs.setReference}
        {...getReferenceProps()}
        //do nothing if clicked
        onClick={(e) => e.preventDefault()}
        className="inline-flex items-center"
      >
        {children}
      </button>
      <FloatingPortal className={`${sizeClasses(size)}`}>
        {isOpen && (
          <div
            className={finalClassName}
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <div className="flex flex-col">
              {/* <div className="flex  justify-between"> */}
              <div className="text-sm font-semibold mb-2">{title}</div>
              <div className="text-xs  font-normal text-slate-300">
                {description}
              </div>
              {/* </div> */}
            </div>
          </div>
        )}
      </FloatingPortal>
    </>
  );
}

export default Tooltip;
