// src/constants/serviceOptions.js

export const lineItemContants = {
  mailings: [
    { name: "Base", value: "base_price" },
    { name: "Setup Fee", value: "setup_fee" },
    { name: "Mail Letter", value: "mail_letter" },
    { name: "Return Mail", value: "return_mail" },
    { name: "Additional Language", value: "additional_language" },
    { name: "Additional Template", value: "additional_template" },
    { name: "Address Append", value: "address_append" },
  ],
  emailings: [
    { name: "Base", value: "base_price" },
    { name: "Setup Fee", value: "setup_fee" },
  ],
  call_center: [
    { name: "Base", value: "base_price" },
    { name: "Setup Fee", value: "setup_fee" },
  ],
  credit_monitoring: [
    { name: "1B 1Y Fixed Price", value: "1b_1y_fixed_price", instruction: "" },
    { name: "1B 2Y Fixed Price", value: "1b_2y_fixed_price", instruction: "" },
    { name: "1B 3Y Fixed Price", value: "1b_3y_fixed_price", instruction: "" },
    { name: "3B 1Y Fixed Price", value: "3b_1y_fixed_price", instruction: "" },
    { name: "3B 2Y Fixed Price", value: "3b_2y_fixed_price", instruction: "" },
    { name: "3B 3Y Fixed Price", value: "3b_3y_fixed_price", instruction: "" },
    {
      name: "1B 1Y Redemption",
      value: "1b_1y_redemption",
      instruction: "Price assuming 3% redemption rate",
    },
    { name: "1B 2Y Redemption", value: "1b_2y_redemption" },
    {
      name: "1B 3Y Redemption",
      value: "1b_3y_redemption",
      instruction: "Price assuming 3% redemption rate",
    },
    {
      name: "3B 1Y Redemption",
      value: "3b_1y_redemption",
      instruction: "Price assuming 3% redemption rate",
    },
    {
      name: "3B 2Y Redemption",
      value: "3b_2y_redemption",
      instruction: "Price assuming 3% redemption rate",
    },
    {
      name: "3B 3Y Redemption",
      value: "3b_3y_redemption",
      instruction: "Price assuming 3% redemption rate",
    },
    {
      name: "Enrollment over 3%",
      value: "enrollment_over_3",
      instruction: "Calculate price for enrollment over 3%",
    },
    {
      name: "Credit Monitoring - Single Bureau",
      value: "single_bureau_credit_monitoring",
    },

    {
      name: "Credit Monitoring - Two Bureau",
      value: "two_bureau_credit_monitoring",
    },
    {
      name: "Credit Monitoring - Three Bureau",
      value: "three_bureau_credit_monitoring",
    },
    { name: "ID Theft Insurance < $100k", value: "id_theft_insurance_100k" },
    { name: "ID Theft Insurance up to $1M", value: "id_theft_insurance_1m" },
    {
      name: "ID Theft Insurance over $1M",
      value: "id_theft_insurance_1m_plus",
    },
    { name: "Dark Web Monitoring", value: "dark_web_monitoring" },
    { name: "Identity Restoration", value: "identity_restoration" },
  ],
  data_mining: [
    { name: "Encrypted Hard Drive", value: "encrypted_hard_drive" },
    { name: "Processing", value: "processing" },
    { name: "Hosting", value: "hosting" },
    {
      name: "Manual Analysis",
      value: "manual_analysis",
      showTooltip: true,
      tooltipText:
        "Use this for manual document viewing, tagging, coding, and searching.",
    },
    { name: "Automated Analysis", value: "automated_analysis" },
    { name: "Foreign Language Analysis", value: "foreign_language_analysis" },
    { name: "Project Management", value: "project_management" },
    { name: "Technical Time", value: "technical_time" },
    { name: "Engagement Closure", value: "engagement_closure" },
    { name: "User Licenses", value: "user_licenses" },
  ],
  document_review: [
    {
      name: "Encrypted Hard Drive",
      value: "encrypted_hard_drive",
      showTooltip: false,
      tooltipText: "If a hard drive must be decrypted, use this option.",
    },
    {
      name: "Processing",
      value: "processing",
      showTooltip: false,
      tooltipText:
        "Use this option for data ingestion and processing into a tool for analysis.",
    },
    { name: "Hosting", value: "hosting" },
    {
      name: "Basic Review",
      value: "basic_review",
      showTooltip: true,
      tooltipText:
        "Use this option for your basic review of documents. This includes tagging or coding documents, but not entity extraction.",
    },
    {
      name: "Extraction Review",
      value: "extraction_review",
      showTooltip: true,
      tooltipText:
        "Use this option for review of documents that includes manual entity extraction.",
    },
    {
      name: "Excel Review",
      value: "excel_review",
      showTooltip: true,
      tooltipText:
        "Use this option if you charge for special review of dense spreadsheets.",
    },
    {
      name: "PDF Review",
      value: "pdf_review",
      showTooltip: true,
      tooltipText: "Use this option if you charge for special review of PDF's.",
    },
    { name: "Foreign Language Review", value: "foreign_language" },
    {
      name: "Automated Entity Extraction",
      value: "automated_entity_extraction",
    },
    {
      name: "Manual Entity Consolidation",
      value: "manual_entity_consolidation",
    },
    {
      name: "Automated Entity Consolidation",
      value: "automated_entity_consolidation",
    },
    { name: "Quality Control", value: "quality_control" },
    { name: "Project Management", value: "project_management" },
    { name: "Technical Time", value: "technical_time" },
    { name: "Engagement Closure", value: "engagement_closure" },
    { name: "User Licenses", value: "user_licenses" },
  ],
  forensics: [{ name: "Base", value: "base_price" }],
  incident_response: [{ name: "Base", value: "base_price" }],
};

export const unitConstants = {
  mailings: [
    { name: "Letter", value: "letter" },
    { name: "Page", value: "page" },
    { name: "Template", value: "template" },
    { name: "Record", value: "record" },
    { name: "Fixed Price", value: "fixed_price" },
  ],
  emailings: [
    { name: "Email", value: "email" },
    { name: "Fixed Price", value: "fixed_price" },
  ],
  call_center: [
    { name: "Call", value: "call" },
    { name: "Minute", value: "minute" },
    { name: "Person", value: "person" },
    { name: "Fixed Price", value: "fixed_price" },
  ],
  credit_monitoring: [
    { name: "Month", value: "month" },
    { name: "Year", value: "year" },
    { name: "Person", value: "person" },
    { name: "Redemption", value: "redemption" },
    { name: "Fixed Price", value: "fixed_price" },
  ],
  data_mining: [
    { name: "Document", value: "document" },
    { name: "Hour", value: "hour" },
    { name: "GB", value: "gb" },
    { name: "Fixed Price", value: "fixed_price" },
  ],
  document_review: [
    { name: "Document", value: "document" },
    { name: "Hour", value: "hour" },
    { name: "GB", value: "gb" },
    { name: "Entity", value: "entity" },
    { name: "Fixed Price", value: "fixed_price" },
  ],
  forensics: [
    { name: "Document", value: "document" },
    { name: "Hour", value: "hour" },
    { name: "GB", value: "gb" },
    { name: "Entity", value: "entity" },
    { name: "Fixed Price", value: "fixed_price" },
  ],
};

export const serviceOptionConstants = {
  //combinations of offered items
  mailings: [],
  emailings: [],
  call_center: [],
  credit_monitoring: [],
  data_mining: [],
  document_review: [],
  forensics: [],
  incident_response: [],
};
export const pricingOptionConstants = {
  mailings: [],
  emailings: [],
  call_center: [
    //m-f 8-5
    //s-s 8-5
    //call in rate
    //sla
    //minimum call vol
    //minimum call time
    //min call fee
  ],
  credit_monitoring: [
    { name: "1B 1Y Fixed Price", value: "1b_1y_fixed_price", instruction: "" },
    { name: "1B 2Y Fixed Price", value: "1b_2y_fixed_price", instruction: "" },
    { name: "1B 3Y Fixed Price", value: "1b_3y_fixed_price", instruction: "" },
    { name: "3B 1Y Fixed Price", value: "3b_1y_fixed_price", instruction: "" },
    { name: "3B 2Y Fixed Price", value: "3b_2y_fixed_price", instruction: "" },
    { name: "3B 3Y Fixed Price", value: "3b_3y_fixed_price", instruction: "" },
    {
      name: "1B 1Y Redemption",
      value: "1b_1y_redemption",
      instruction: "Price assuming 3% redemption rate",
    },
    { name: "1B 2Y Redemption", value: "1b_2y_redemption" },
    {
      name: "1B 3Y Redemption",
      value: "1b_3y_redemption",
      instruction: "Price assuming 3% redemption rate",
    },
    {
      name: "3B 1Y Redemption",
      value: "3b_1y_redemption",
      instruction: "Price assuming 3% redemption rate",
    },
    {
      name: "3B 2Y Redemption",
      value: "3b_2y_redemption",
      instruction: "Price assuming 3% redemption rate",
    },
    {
      name: "3B 3Y Redemption",
      value: "3b_3y_redemption",
      instruction: "Price assuming 3% redemption rate",
    },
    {
      name: "Enrollment over 3%",
      value: "enrollment_over_3",
      instruction: "Calculate price for enrollment over 3%",
    },
  ],
  data_mining: [],
  document_review: [],
  forensics: [],
  incident_response: [],
};

export const scenarioConstants = {
  mailings: [
    {
      name: "Default",
      line_items: [
        {
          name: "Base",
          value: "base_price",
          description:
            "This scenario asks you to enter a single price for the entire service.",
        },
      ],
    },
    {
      name: "Mailing with Return and NCOA Flat Rate",
      description: "Flat fee Mailing that includes return mail and NCOA.",
      line_items: [
        { name: "All Inclusive Mailing", value: "all_inclusive_mailing" },
      ],
    },
    {
      name: "Mailing with Return and NCOA",
      description: "Variable fee Mailing with return mail and NCOA.",
      line_items: [
        { name: "Setup Fee", value: "setup_fee" },
        {
          name: "Mailing",
          value: "mailing",
          instructions: "Be sure to price the number of pages requested.",
        },
        {
          name: "Postage",
          value: "postage",
          instructions: "Only if not included",
        },
        { name: "NCOA", value: "ncoa" },
        { name: "Return Mail", value: "return_mail" },
      ],
    },
  ],
  emailings: [
    {
      name: "Default",
      description:
        "This scenario asks you to enter a single price for the entire service.",
      line_items: [{ name: "Send emails", value: "send_emails" }],
    },
  ],
  call_center: [
    {
      name: "Default",
      line_items: [
        { name: "Monday - Friday 8-5", value: "m-f-8-5" },
        { name: "Weekend Hourly", value: "setup_fee" },
        { name: "Call In Rate", value: "call_in_rate" },
        { name: "SLA", value: "sla" },
        { name: "Minimum Call Volume", value: "minimum_call_volume" },
        { name: "Minimum Call Time", value: "minimum_call_time" },
        { name: "Minimum Call Fee", value: "minimum_call_fee" },
      ],
    },
  ],
  credit_monitoring: [
    {
      name: "1B 1Y Redemption",
      description: "1 Bureau 1 Year, Price assuming 3% redemption rate",
      line_items: [
        { name: "1B Per Enrollment up to 3%", value: "enrollment_up_to_3%" },
        { name: "Enrollment over 3% up to min", value: "enrollment_over_3" },
        { name: "Enrollment over minimum", value: "enrollment_over_minimum" },
        // {
        //   name: "Over-the-phone enrollment",
        //   value: "over_the_phone_enrollment",
        // },
      ],
    },
    {
      name: "1B 2Y Redemption",
      description: "1 Bureau 2 Year, Price assuming 3% redemption rate",
      line_items: [
        { name: "1B Per Enrollment up to 3%", value: "enrollment_up_to_3%" },
        { name: "Enrollment over 3% up to min", value: "enrollment_over_3" },
        { name: "Enrollment over minimum", value: "enrollment_over_minimum" },
        // {
        //   name: "Over-the-phone enrollment",
        //   value: "over_the_phone_enrollment",
        // },
      ],
    },
    {
      name: "1B 3Y Redemption",
      description: "1 Bureau 3 Year, Price assuming 3% redemption rate",
      line_items: [
        { name: "1B Per Enrollment up to 3%", value: "enrollment_up_to_3%" },
        { name: "Enrollment over 3% up to min", value: "enrollment_over_3" },
        { name: "Enrollment over minimum", value: "enrollment_over_minimum" },
        // {
        //   name: "Over-the-phone enrollment",
        //   value: "over_the_phone_enrollment",
        // },
      ],
    },
    {
      name: "3B 1Y Redemption",
      description: "3 Bureau 1 Year, Price assuming 3% redemption rate",
      line_items: [
        { name: "3B Per Enrollment up to 3%", value: "enrollment_up_to_3%" },
        { name: "Enrollment over 3% up to min", value: "enrollment_over_3" },
        { name: "Enrollment over minimum", value: "enrollment_over_minimum" },
        // {
        //   name: "Over-the-phone enrollment",
        //   value: "over_the_phone_enrollment",
        // },
      ],
    },
    {
      name: "3B 2Y Redemption",
      description: "3 Bureau 2 Year, Price assuming 3% redemption rate",
      line_items: [
        { name: "3B Per Enrollment up to 3%", value: "enrollment_up_to_3%" },
        { name: "Enrollment over 3% up to min", value: "enrollment_over_3" },
        { name: "Enrollment over minimum", value: "enrollment_over_minimum" },
      ],
    },
    {
      name: "3B 3Y Redemption",
      description: "3 Bureau 3 Year, Price assuming 3% redemption rate",
      line_items: [
        { name: "3B Per Enrollment up to 3%", value: "enrollment_up_to_3%" },
        { name: "Enrollment over 3% up to min", value: "enrollment_over_3" },
        { name: "Enrollment over minimum", value: "enrollment_over_minimum" },
      ],
    },
    {
      name: "1B 1Y Fixed Price",
      description: "1 Bureau 1 Year, Fixed Price",
      line_items: [{ name: "1B 1Y Fixed Price", value: "1b_fixed_price" }],
    },
    {
      name: "1B 2Y Fixed Price",
      description: "1 Bureau 2 Year, Fixed Price",
      line_items: [{ name: "1B 2Y Fixed Price", value: "1b_fixed_price" }],
    },
    {
      name: "1B 3Y Fixed Price",
      description: "1 Bureau 3 Year, Fixed Price",
      line_items: [{ name: "1B 3Y Fixed Price", value: "1b_fixed_price" }],
    },
    {
      name: "3B 1Y Fixed Price",
      description: "3 Bureau 1 Year, Fixed Price",
      line_items: [{ name: "3B 1Y Fixed Price", value: "3b_fixed_price" }],
    },
    {
      name: "3B 2Y Fixed Price",
      description: "3 Bureau 2 Year, Fixed Price",
      line_items: [{ name: "3B 2Y Fixed Price", value: "3b_fixed_price" }],
    },
    {
      name: "3B 3Y Fixed Price",
      description: "3 Bureau 3 Year, Fixed Price",
      line_items: [{ name: "3B 3Y Fixed Price", value: "3b_fixed_price" }],
    },
  ],
  data_mining: [
    {
      name: "Default",
      description:
        "This scenario asks you to enter a single price for the entire service.",
      line_items: [
        {
          name: "Data mining",
          value: "all_inclusive_data_mining",
        },
      ],
    },
  ],
  document_review: [
    {
      name: "Default",
      line_items: [{ name: "Doc review", value: "all_inclusive_doc_review" }],
    },
  ],
  forensics: [
    {
      name: "Default",
      line_items: [{ name: "Forensics", value: "forensics" }],
    },
  ],
  incident_response: [
    {
      name: "Default",
      line_items: [{ name: "Incident Response", value: "incident_response" }],
    },
  ],
  managed_detection_and_response: [
    {
      name: "Default",
      line_items: [
        {
          name: "Managed Detection and Response",
          value: "managed_detection_and_response",
        },
      ],
    },
  ],
  betterment: [
    {
      name: "Default",
      line_items: [{ name: "Betterment", value: "betterment" }],
    },
  ],
  ediscovery_document_review: [
    {
      name: "Default",
      line_items: [
        {
          name: "E-Discovery Document Review",
          value: "ediscovery_document_review",
        },
        {
          name: "",
          value: "",
        },
        {
          name: "",
          value: "",
        },
      ],
    },
  ],
};

export const pricingAssumptionConstants = {
  mailings: [
    {
      name: "What is your SLA?",
      value: "What is your SLA?",
    },
  ],
  emailings: [],
  call_center: [],
  credit_monitoring: [],
  data_mining: [],
  document_review: [],
  forensics: [],
  incident_response: [],
};
