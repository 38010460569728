import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { Spreadsheet } from "react-spreadsheet";
import "../../css/spreadsheet.css";
import Button from "../../elements/Button";
import Input from "../../components/Input";
import { Icon } from "@iconify/react";
import { set } from "date-fns";
import ComboboxSelector from "../ComboBoxSelector";
import MergedCell from "./SpreadsheetComponents";

const SpreadsheetComponent = ({
  pricingItems,
  initialLineItems,
  service,
  handlePriceChange,
  handleSetEstimatedPrice,
  option,
  handleDeleteOption,
  name,
  initialDescription,
  handleChangeDescription,
  index,
  scenarios,
  servicePrice,
}) => {
  const [data, setData] = useState([]);
  const [pricing, setPricing] = useState({});
  const [initialDataSet, setInitialDataSet] = useState(false);
  const [totalSum, setTotalSum] = useState(servicePrice || 0);
  const [editing, setEditing] = useState(
    initialDescription && initialDescription !== "" ? false : true
  );
  const [description, setDescription] = useState(initialDescription);
  const [selectedScenario, setSelectedScenario] = useState(null);
  const [lineItems, setLineItems] = useState([]);
  const prevDataRef = useRef([]);
  const debounceTimeoutRef = useRef(null);

  useEffect(() => {
    setPricing(pricingItems);
    console.log("Spreadsheet <PRICING>", pricing);
  }, [pricingItems]);

  useEffect(() => {
    console.log("Scenarios", scenarios);
  }, [scenarios]);

  useEffect(() => {
    console.log("Initial Description", initialDescription);
    if (initialDescription && initialDescription !== "") {
      setSelectedScenario(initialDescription);
    }
  }, [initialDescription]);

  useEffect(() => {
    console.log("Selected scenario", selectedScenario);
    if (selectedScenario === null) return;
    findScenarioByName();
  }, [selectedScenario]);

  const findScenarioByName = () => {
    console.log("Scenarios", scenarios);

    let scenario = scenarios.find((s) => s.name === selectedScenario);
    console.log("Scenario found", scenario);
    let items = scenario?.line_items;
    console.log("Scenario Items", items);
    setLineItems(items);
  };

  useEffect(() => {
    //this wont work until I add a check
    //to see if the totalSum is different than the lineItem price
    //but I have to figure out how to get the lineItem price
    let e = {
      target: {
        name: "price",
        value: totalSum,
      },
    };
    // handleFieldChange called handleSetEstimatedPrice price 1000
    handleSetEstimatedPrice(e, totalSum);
  }, [totalSum]);

  useEffect(() => {
    //calculate total sum of all line items
    let total = 0;
    lineItems.forEach((lineItem) => {
      let unitPrice = pricingItems?.[lineItem.name]?.unitPrice || 0;
      let qty = pricingItems?.[lineItem.name]?.qty || 0;
      let price;
      if (pricingItems?.[lineItem.name]?.price) {
        price = parseFloat(pricingItems?.[lineItem.name]?.price);
      } else {
        price = unitPrice * qty;
      }
      total += price;
    });
    setTotalSum(total);
  }, [pricingItems]);

  useEffect(() => {
    console.log("Scenario Line Items Changed", lineItems);
    if (!pricingItems || !lineItems || initialDataSet) {
      console.log("Scenario Returning due to one of the following conditions", {
        pricingItems,
        lineItems,
        initialDataSet,
      });
    }
    console.log("Scenario Initial Data Set", initialDataSet);
    let initialData = [
      [
        { value: "Item" },
        { value: "Price" },
        { value: "Qty" },
        { value: "Total" },
      ],
    ];

    let myTotal = 0;
    if (myTotal < 1) {
      lineItems.forEach((lineItem) => {
        //   if (!pricingItems[lineItem.name]) return;
        console.log("initialData Line Item", lineItem);
        console.log("initialData Pricing", pricingItems[lineItem.name]);
        let unitPrice = pricingItems?.[lineItem.name]?.unitPrice || 0;
        let qty = pricingItems?.[lineItem.name]?.qty || 0;
        let price;
        if (pricingItems?.[lineItem.name]?.price) {
          price = parseFloat(pricingItems?.[lineItem.name]?.price);
        } else {
          price = unitPrice * qty;
        }

        let total = price;
        myTotal += total;
        initialData.push([
          { value: lineItem.name },
          { value: unitPrice },
          { value: qty },
          {
            value: `${new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(total)}`,
          },
        ]);
      });
    }

    // Add the total row at the bottom
    initialData.push([
      { value: "" },
      { value: "" },
      { value: "" },
      { value: "" },
    ]);
    initialData.push([
      { value: "Total" },
      { value: "" },
      { value: "" },
      {
        value: `${new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(myTotal)}`,
        className: "bg-[#FFFF00]",
      },
    ]);
    // initialData = assumptionRows(initialData);
    setData(initialData);
    setTotalSum(myTotal);
    setInitialDataSet(true); // Mark initial data as set
    // }
  }, [lineItems, pricingItems]);

  // Spreadsheet Cells
  const assumptionRows = (data) => {
    // Add 3 blank rows
    data.push([{ value: "" }, { value: "" }, { value: "" }, { value: "" }]);
    data.push([{ value: "" }, { value: "" }, { value: "" }, { value: "" }]);
    data.push([{ value: "" }, { value: "" }, { value: "" }, { value: "" }]);

    // Add a row with 4 merged cells
    data.push([
      { value: "Assumptions", component: MergedCell },
      { value: "", forceComponent: true },
      { value: "", forceComponent: true },
      { value: "", forceComponent: true },
    ]);

    return data;
  };
  const debounce = (func, delay) => {
    // console.log("<");
    return (...args) => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
      debounceTimeoutRef.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debouncedHandlePriceChange = useCallback(
    debounce(handlePriceChange, 1000),
    [handlePriceChange]
  );

  const handleChange = (newData) => {
    let newObj = {};
    let dataChanged = false; // Flag to track if data has changed

    const updatedData = newData.map((row, rowIndex) => {
      if (rowIndex === 0) return row; // Skip header row

      const price = parseFloat(String(row[1]?.value).replace("$", "")) || 0;
      const qty = parseInt(row[2]?.value, 10) || 0;
      const total = price * qty;

      // Check if any cell in the row has changed
      let rowChanged = false;
      for (let colIndex = 0; colIndex < row.length; colIndex++) {
        const cell = row[colIndex];
        const prevCell = prevDataRef.current[rowIndex]?.[colIndex];
        if (cell.value !== prevCell?.value) {
          rowChanged = true;
          dataChanged = true; // Set flag to true if any cell has changed
          console.log(`Cell changed at [${rowIndex}, ${colIndex}]:`, {
            oldValue: prevCell?.value,
            newValue: cell.value,
          });
          break; // Stop searching once a change is detected
        }
      }
      if (rowChanged) {
        // Create the object with the required structure
        const key = row[0].value; // Use the value from the first column as the key
        if (key === "Total") return;
        newObj = {
          type: "Standard", // hard coded
          unit: "Page", // hard coded
          unitPrice: price.toFixed(2), // calculated value
          qty: qty, // hard coded
          price: total.toFixed(2), // calculated value
        };

        // Call handlePriceChange with the created object
        //only call this if typing has stopped for 2 seconds
        debouncedHandlePriceChange(newObj, service, key);
      }

      return [
        row[0],
        row[1],
        row[2],
        {
          value: `${new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(total)}`,
        },
      ];
    });

    // Ensure the next-to-last row has blank values
    updatedData[updatedData.length - 2] = [
      { value: "" },
      { value: "" },
      { value: "" },
      { value: "" },
    ];

    // Update the last row with the total sum
    // Calculate the total sum of the total column
    const sheetTotal = updatedData
      .slice(1, updatedData.length - 1)
      .reduce((acc, row) => {
        const numericValue = parseFloat(row[3].value.replace(/[^0-9.-]+/g, ""));
        return acc + (isNaN(numericValue) ? 0 : numericValue);
      }, 0);

    updatedData[updatedData.length - 1] = [
      { value: "Total" },
      { value: "" },
      { value: "" },
      {
        value: `${new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(sheetTotal)}`,
        className: "bg-[#FFFF00]",
      },
    ];

    // Update the previous data reference
    prevDataRef.current = newData;

    // Update the state with the modified data only if data has changed
    if (dataChanged) {
      setData(updatedData);
      setTotalSum(sheetTotal); // Update the total sum state
    }
  };
  const onChangeDescription = (e) => {
    // setDescription(e.target.value);
    setDescription(e);
  };
  const onSaveDescription = (desc) => {
    setEditing(false);
    let e = {
      target: {
        name: "description",
        value: desc,
      },
    };
    console.log("onSaveDescription", e.target.name, e.target.value);
    handleChangeDescription(e);
  };

  return (
    <div className="w-full pt-4">
      <div className="flex  items-center justify-between mb-2">
        <div>
          {editing ? (
            <>
              <div className="flex items-center space-x-2">
                <div className=" flex rounded-md shadow-sm w-96">
                  <ComboboxSelector
                    name={"field"}
                    options={scenarios}
                    fieldType={"single"}
                    open={true}
                    handleSelect={(v) => {
                      setSelectedScenario(v);
                      setDescription(v);
                      onSaveDescription(v);
                    }}
                    // handleSelect(v, service, field)}
                    handleDeselect={(v) => setSelectedScenario(v)}
                    // handleDeselect}
                    handleClearAllSelections={
                      (value) => console.log("handleClearAllSelections", value)
                      // handleClearAllSelections(value, service, field)
                    } //{handleClearAllSelections}
                    // value={opts2[field] ? opts2[field] : null}
                    value={selectedScenario}
                  />
                </div>
                {/* <Input
                  id="description"
                  name="description"
                  placeholder="Short description"
                  value={description}
                  onChange={(e) => {
                    onChangeDescription(e);
                  }}
                /> */}
                <Icon
                  icon="mdi:content-save-edit-outline" //save icon
                  className="cursor-pointer text-slate-500 hover:text-slate-700 h-6 w-6"
                  onClick={() => onSaveDescription(description)}
                />
              </div>
            </>
          ) : (
            <h2 className="flex items-center">
              {description}
              <Icon
                icon="mdi:pencil"
                className="ml-1 cursor-pointer text-slate-500 hover:text-slate-700"
                onClick={() => setEditing(true)}
              ></Icon>{" "}
            </h2>
          )}
          <div className="text-sm text-slate-500">
            Service Price: {totalSum}
          </div>
        </div>

        <div>
          {index === 0 ? (
            <span className="text-sm"></span>
          ) : (
            <Button color="link" handleClick={handleDeleteOption}>
              Delete option
            </Button>
          )}
        </div>
      </div>
      <Spreadsheet data={data} onChange={handleChange} className="w-full" />
    </div>
  );
};

export default SpreadsheetComponent;
