import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useState } from "react";
import { _auth } from "../modules/_auth";
import Logo from "../images/logo.png";
import Spinner from "../images/spinner.png";
import { utils } from "../modules/_utils";
import { _assessment } from "../modules/_assessment";
import { usePersistStore } from "../store/usestore";
import Button from "../elements/Button";
import { set } from "date-fns";
import Input from "../components/Input";
import { Solicitations } from "../modules/_solicitations";
//import path from "path";

function VerifyVendor(props) {
  const mvp = false;
  const history = useHistory();
  const { verifyVendorLogin } = usePersistStore();
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [name, setName] = useState("");
  const [company, setCompanyName] = useState("");
  const {
    token,
    userId,
    email,
    tenantId,
    type,
    inviteId,
    solicitationId,
    vendorCompanyId,
    version,
    pricingVersion,
  } = useParams();
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [verifyEmail, setVerifyEmail] = useState("");

  useEffect(() => {
    const verifyBidRequestExists = async () => {
      if (solicitationId) {
        console.log("useEffect DEBUG --> solicitationId", solicitationId);
        const solicitations = await Solicitations.getById({
          solicitationId: solicitationId,
        });
        console.log("useEffect DEBUG --> solicitations", solicitations);
        // let solicitationIndexObj = solicitations?.findIndex(
        //   (t) => t._id === solicitationId
        // );
        let solicitationIndexObj = solicitations._id ? solicitations._id : -1;
        if (
          solicitationIndexObj == -1 ||
          !solicitationIndexObj ||
          solicitationIndexObj == undefined
        ) {
          console.log(
            "DEBUG --> solicitationIndexObj not found",
            solicitationIndexObj
          );
          let path = "/access-denied";
          history.push({
            pathname: path,
            state: {
              text: "Bid Request No Longer Exists",
              description:
                "We're sorry, but the bid request you are trying to access is no longer available.",
            },
          });
        } else {
          console.log("DEBUG --> solicitationIndexObj", solicitationIndexObj);
        }
      }
    };
    verifyBidRequestExists();
  }, [solicitationId]);

  console.log("DEBUG --> inviteId from params()", inviteId);
  const verifyGuest = async (event) => {
    setLoader(true);
    event.preventDefault();

    //Validates()
    if (name == "" || verifyEmail == "") {
      setError(true);
      setErrorMessage(
        "Looks like you left one of the fields blank.  Please fill in both fields to continue."
      );
      setLoader(false);
      return;
    } else {
      // event.preventDefault();
      console.info(event);
      //let path = "/home";

      let profile = await verifyVendorLogin(
        userId,
        token,
        name,
        email,
        company,
        tenantId,
        type,
        inviteId,
        "",
        history,
        solicitationId,
        vendorCompanyId,
        version,
        pricingVersion
      );
    }
  };
  const handleNameChange = (event) => {
    setError(false);
    setErrorMessage("");
    setName(event.target.value);
  };
  const handleEmailChange = (event) => {
    setError(false);
    setErrorMessage("");
    setVerifyEmail(event.target.value);
  };
  const handleCompanyChange = (event) => {
    setError(false);
    setErrorMessage("");
    setCompanyName(event.target.value);
  };

  return (
    <main className="bg-white">
      <div className="relative md:flex justify-center">
        {/* Content */}
        <div className=" justify-center content-center">
          <div className="min-h-screen h-full flex flex-col content-center justify-center">
            <div className="flex flex-row mx-auto px-4 py-8 items-center">
              <img
                className="inline-flex w-16 mx-auto items-center mr-3"
                src={Logo}
              />
              <h2 className="flex mx-auto my-auto items-center text-3xl text-slate-800 font-semibold">
                Welcome to Breachlink.
              </h2>
            </div>

            <div className="max-w-sm mx-auto px-4 ">
              <h2 className="flex text-lg text-slate-800 font-semibold mb-6">
                Enter your name and email to continue.
              </h2>
              {/* Form */}
              <form onSubmit={verifyGuest}>
                <div className="space-y-4">
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      Name
                    </label>
                    <Input
                      id="name"
                      value={name}
                      onChange={(e) => handleNameChange(e)}
                      // className="h-12 rounded w-full
                      // focus:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] focus:shadow-accent-500
                      // border-gray-300 py-2 px-3 shadow-sm
                      // hover:border-gray-400
                      // focus:border-none  focus:ring-1 focus:ring-brand-500"
                      type="text"
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      Verify Email Address
                    </label>
                    <Input
                      id="email"
                      value={verifyEmail}
                      onChange={(e) => handleEmailChange(e)}
                      // className="h-12 rounded w-full
                      // focus:shadow-[inset_0px_0px_2px_0px_rgba(0,0,0,0.3)] focus:shadow-accent-500
                      // border-gray-300 py-2 px-3 shadow-sm
                      // hover:border-gray-400
                      // focus:border-none  focus:ring-1 focus:ring-brand-500"
                      type="text"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between mt-6">
                  <Button
                    fullWidth
                    enabled={!loader}
                    loader={loader}
                    type="submit"
                    size="lg"
                    className="btn btn-primary"
                    audit={false}
                    action="View Bid Request Details"
                    details={{
                      "Invite Id": inviteId,
                      "Vendor Name": name,
                      "Vendor Email": verifyEmail,
                    }}

                    //handleClick={Validates()}
                  >
                    View Bid Request Details
                  </Button>
                </div>
              </form>

              {/* Footer */}
              {isAuthenticated && (
                <div className="flex items-center justify-center mt-6 text-red-500 text-sm">
                  Your token is Invalid - Please contact admin.
                </div>
              )}
              {error && (
                <div className="flex items-center justify-center mt-6 text-red-500 text-sm">
                  {errorMessage}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default VerifyVendor;
