// src/components/MergedCell.js
import React from "react";
import { DataViewer } from "react-spreadsheet";

const MergedCell = ({ cell, getValue }) => {
  const value = getValue({ data: cell });
  return (
    <td colSpan={4} style={{ backgroundColor: "#FFFF00" }}>
      {value}
    </td>
  );
};

export default MergedCell;
